import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountComponent } from './account/account.component';
import { GridsterModule } from 'angular-gridster2';
import { DragDropModule } from '@angular/cdk/drag-drop';

// Material
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatOptionModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { Options } from '@angular-slider/ngx-slider';
import { NgxSliderModule } from 'ngx-slider-v2';
import { MatSortModule } from '@angular/material/sort';
import { MatBadgeModule } from '@angular/material/badge';

import {
    MatTooltipModule,
    MatTooltipDefaultOptions as MatTooltipDefaultOptions
} from '@angular/material/tooltip';
import {
    MatBottomSheetModule,
    MAT_BOTTOM_SHEET_DEFAULT_OPTIONS
} from '@angular/material/bottom-sheet';
export const OtherOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    position: 'before',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true
};

import { MatProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTableModule as MatTableModule } from '@angular/material/table';
import { RouterModule } from '@angular/router';
import { MatSliderModule } from '@angular/material/slider';
import { QueryBuilderService } from '@cubejs-client/ngx';

@NgModule({
    declarations: [AccountComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    imports: [
        RouterModule,
        CommonModule,

        /** Angular Material imports **/
        MatAutocompleteModule,
        MatInputModule,
        MatOptionModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatProgressBarModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTableModule,
        MatMenuModule,
        MatTabsModule,
        MatDialogModule,
        MatIconModule,
        MatSnackBarModule,
        MatListModule,
        MatSortModule,
        MatCardModule,
        MatBottomSheetModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatBadgeModule,

        // other styling exports
        DragDropModule,
        GridsterModule,
        MatSliderModule,
        MatBadgeModule,
        RouterModule
        // CubejsClientModule,
    ],
    providers: [
        QueryBuilderService,
        {
            provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
            useValue: {
                hasBackdrop: false
            }
        },
        Options
    ],
    exports: [
        AccountComponent,
        RouterModule,
        CommonModule,

        /** Angular Material imports **/
        MatAutocompleteModule,
        MatInputModule,
        MatOptionModule,
        MatDatepickerModule,
        MatExpansionModule,
        MatSidenavModule,
        MatToolbarModule,
        MatTooltipModule,
        MatNativeDateModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatProgressBarModule,
        MatBadgeModule,
        MatSelectModule,
        MatFormFieldModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSlideToggleModule,
        MatTableModule,
        MatBadgeModule,
        MatMenuModule,
        MatTabsModule,
        MatDialogModule,
        MatIconModule,
        MatSnackBarModule,
        MatListModule,
        MatSortModule,
        MatCardModule,
        MatBottomSheetModule,
        MatProgressSpinnerModule,
        MatSidenavModule,
        MatSliderModule,

        // other styling exports
        DragDropModule,
        GridsterModule,
        FontAwesomeModule,
        NgxSliderModule
    ]
})
export class SharedModule {}
