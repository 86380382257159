import { BrowserModule, HammerModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
    HttpClientModule,
    HttpClientXsrfModule,
    HTTP_INTERCEPTORS
} from '@angular/common/http';

import { NgChartsModule } from 'ng2-charts';

import { MAT_BOTTOM_SHEET_DEFAULT_OPTIONS } from '@angular/material/bottom-sheet';
import { ColorPickerModule } from 'ngx-color-picker';
import { AngularSignaturePadModule } from '@maykc/angular-signature-pad';
import { NgxLoadingModule } from 'ngx-loading';
import { MomentModule } from 'ngx-moment';
import { AngularDraggableModule } from 'angular2-draggable';
import { NgxSliderModule } from 'ngx-slider-v2';

// Font awesome
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import 'mousetrap';
import { GalleryModule } from '@ks89/angular-modal-gallery';

// Videogular2
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { PowerBIEmbedModule } from 'powerbi-client-angular';

// Authentication
import { AuthInterceptor } from './_extra/auth.interceptor';

import { SearchPipe } from './_extra/search.pipe';

// Routing
import { Routing } from './app.routing';

// Pathfinding
import { RoutingComponent } from './routing/routing.component';

// Components
import { AppComponent } from './app.component';
import { MapComponent } from './map/map.component';

// User Settings
import {
    SettingCustomExtentComponent,
    SettingCustomLegendComponent,
    SharedConfigOverviewComponent
} from './setting';
// Map creator
import {
    MapFromCSVComponent,
    MapFromKmlFileComponent,
    MapFromDwgFileComponent,
    MapFromShapeFileComponent
} from './map-creator';

// Legend
import { LegendComponent } from './legend/legend.component';
import { StylingComponent } from './legend/styling/styling.component';
import { LegendMapGroupComponent } from './legend/mapgroup/mapgroup.component';
import { LegendMapComponent } from './legend/map/map.component';
import { LegendGroupComponent } from './legend/group/group.component';
import { LegendLayerComponent } from './legend/layer/layer.component';

import {
    PrintComponent,
    InfoComponent,
    CycloComponent,
    ObliqueComponent,
    GeolocationComponent,
    EditComponent,
    MeasureComponent,
    RedlineComponent,
    BottomSheetComponent,
    PolyInfoComponent,
    CircleInfoComponent,
    GetCoordinateComponent,
    NavigationComponent,
    CreateCustomExtentComponent,
    ZoomToCoordinateComponent,
    VrPhotoComponent,
    DownloadComponent,
    ShareComponent,
    BackgroundComponent,
    BufferComponent,
    BufferSnackBarComponent,
    ShareDialogComponent,
    SnapGetCoordinateComponent
} from './tools';
import { DetailComponent } from './forms/detail/detail.component';
import { LoginComponent } from './authentication/login/login.component';
import { ResetComponent } from './authentication/reset/reset.component';
import { TwoFactorComponent } from './authentication/two-factor/two-factor.component';
import { SearchComponent } from './search/search.component';
import { FilterComponent } from './filter/filter.component';
import { DetailFormComponent } from './forms/detailform/detailform.component';
import { MessageComponent } from './message/message.component';
import { PasswordComponent } from './authentication/password/password.component';
import { MessagesComponent } from './messages/messages.component';
import { GridComponent } from './grid/grid.component';
import { QueryComponent } from './query/query.component';
import { CustomExtentComponent } from './custom-extent/custom-extent.component';
// Dialogs
import { MediaDialogComponent } from './grid/dialogs/media.dialog';
import {
    DeleteDialogComponent,
    MapOpacitySliderComponent,
    StreetviewComponent,
    SelectExportDialogComponent,
    UserManualDialogComponent,
    UserPasswordResetDialogComponent,
    CreateNotificationDialogComponent,
    MetadataEditDialogComponent,
    MetadataDialogComponent,
    ApprovingDialogComponent,
    MultiFactorAuthDialogComponent,
    DashboardDialogComponent,
    NotificationDetailDialogComponent
} from './_dialogs';
import { CustomExtentDialogComponent } from './tools/create-custom-extent/create-custom-extent.component';
import { SettingsDialogComponent } from './setting/setting.component';

import { MapCreatorDialogComponent } from './map-creator/map-creator.component';
import { ZoomToCoordinateDialogComponent } from './tools/zoom-to-coordinate/zoom-to-coordinate.component';
import { VrViewerDialogComponent } from './tools/vr-photo/vr-viewer/vr-viewer';

// Services
import {
    MapService,
    LayerService,
    ConfigService,
    InteractionService,
    FeatureService,
    DrawService,
    DetailService,
    GridService,
    AuthService,
    EditService,
    ClickService,
    CqlService,
    QueryService,
    ExcelService,
    LegendService,
    CustomExtentService,
    SidenavService
} from 'app/_services';

// Guards
import { AuthGuard } from './_guards/auth.guard';
import { RoleGuard } from './_guards/role.guard';
import { PublicGuard } from './_guards/public.guard';

// Environment
import { environment } from 'environments/environment';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SharedModule } from './shared.module';
// import { CubejsClientModule } from '@cubejs-client/ngx';
import { ChartRendererComponent } from './_dialogs/dashboard/chart-renderer/chart-renderer.component';
import { HeaderComponent } from './admin/header/header.component';
import { MapFromWMSComponent } from './map-creator/from-wms/from-wms.component';
import { MapFromXLSXComponent } from './map-creator/from-xlsx/from-xlsx.component';
import { EditGridDialog } from './grid/dialogs/edit-grid.dialog';
import { FormOverviewComponent } from './forms/form-overview/form-overview.component';
import { FormDetailDialog } from './admin/forms/list/form_list.component';
import {
    MatPaginatorIntl,
    MatPaginatorModule
} from '@angular/material/paginator';
import { MessageDialogComponent } from './forms/message/message.dialog';

// Create our declarations array
const declarations = [
    HeaderComponent,
    AppComponent,
    MapComponent,
    LegendComponent,
    LegendMapComponent,
    LegendMapGroupComponent,
    LegendGroupComponent,
    LegendLayerComponent,
    MeasureComponent,
    DetailComponent,
    LoginComponent,
    ResetComponent,
    TwoFactorComponent,
    InfoComponent,
    PolyInfoComponent,
    CircleInfoComponent,
    BufferComponent,
    CycloComponent,
    ObliqueComponent,
    SearchComponent,
    FilterComponent,
    EditComponent,
    GeolocationComponent,
    DetailFormComponent,
    MessageComponent,
    PasswordComponent,
    RedlineComponent,
    BottomSheetComponent,
    BackgroundComponent,
    MessagesComponent,
    GridComponent,
    MediaDialogComponent,
    DeleteDialogComponent,
    CustomExtentDialogComponent,
    EditGridDialog,
    SettingsDialogComponent,
    ZoomToCoordinateDialogComponent,
    MapOpacitySliderComponent,
    StreetviewComponent,
    VrViewerDialogComponent,
    FormDetailDialog,
    GetCoordinateComponent,
    SnapGetCoordinateComponent,
    ZoomToCoordinateComponent,
    NavigationComponent,
    CustomExtentComponent,
    SharedConfigOverviewComponent,
    CreateCustomExtentComponent,
    SettingCustomExtentComponent,
    SettingCustomLegendComponent,
    MapFromCSVComponent,
    MapFromKmlFileComponent,
    MapFromShapeFileComponent,
    MapFromWMSComponent,
    MapFromDwgFileComponent,
    MapFromXLSXComponent,
    QueryComponent,
    UserManualDialogComponent,
    UserPasswordResetDialogComponent,
    CreateNotificationDialogComponent,
    SelectExportDialogComponent,
    MapCreatorDialogComponent,
    MetadataEditDialogComponent,
    MetadataDialogComponent,
    ApprovingDialogComponent,
    DashboardDialogComponent,
    ShareDialogComponent,
    MessageDialogComponent,
    SearchPipe,
    MultiFactorAuthDialogComponent,
    VrPhotoComponent,
    DownloadComponent,
    StylingComponent,
    RoutingComponent,
    ShareComponent,
    PrintComponent,
    ChartRendererComponent,
    BufferSnackBarComponent,
    FormOverviewComponent,
    NotificationDetailDialogComponent
];

// Create our imports array
const imports = [
    BrowserModule,
    NgxSliderModule,
    BrowserAnimationsModule,
    HammerModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    MatPaginatorModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
        cookieName: 'XSRF-TOKEN',
        headerName: 'X-XSRF-TOKEN'
    }),
    Routing,

    // Videogular2
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxLoadingModule,
    PowerBIEmbedModule,

    /** Other imports **/
    AngularSignaturePadModule,
    ColorPickerModule,
    ServiceWorkerModule.register(`${environment.subDirectory}ngsw-worker.js`, {
        enabled: environment.production
    }),
    NgChartsModule,
    GalleryModule,
    MomentModule,
    AngularDraggableModule,
    SharedModule
];

const providers = [
    MapService,
    LayerService,
    ConfigService,
    FeatureService,
    InteractionService,
    DrawService,
    DetailService,
    GridService,
    AuthService,
    AuthGuard,
    RoleGuard,
    PublicGuard,
    EditService,
    ClickService,
    CqlService,
    QueryService,
    ExcelService,
    LegendService,
    CustomExtentService,
    SidenavService,
    AuthInterceptor,
    {
        provide: HTTP_INTERCEPTORS,
        useClass: AuthInterceptor,
        multi: true
    },
    {
        provide: MAT_BOTTOM_SHEET_DEFAULT_OPTIONS,
        useValue: {
            hasBackdrop: false
        }
    },
    {
        provide: MatPaginatorIntl,
        useValue: (() => {
            const paginatorIntl = new MatPaginatorIntl();

            paginatorIntl.itemsPerPageLabel = 'Items per pagina:';
            paginatorIntl.nextPageLabel = 'Volgende pagina';
            paginatorIntl.previousPageLabel = 'Vorige pagina';
            paginatorIntl.firstPageLabel = 'Eerste pagina';
            paginatorIntl.lastPageLabel = 'Laatste pagina';
            //   paginatorIntl.getRangeLabel = (page: number, pageSize: number, length: number) => {
            //     if (length === 0 || pageSize === 0) {
            //       return `0 van ${length}`;
            //     }
            //     const startIndex = page * pageSize;
            //     const endIndex = startIndex < length
            //       ? Math.min(startIndex + pageSize, length)
            //       : startIndex + pageSize;
            //     return `${startIndex + 1} - ${endIndex} van ${length}`;
            //   };

            return paginatorIntl;
        })()
    }
];

const exports = [FormsModule, ReactiveFormsModule];
const bootstrap = [AppComponent];

@NgModule({
    declarations: declarations,
    imports: imports,
    providers: providers,
    exports: exports,
    bootstrap: bootstrap
})
export class AppModule {}
